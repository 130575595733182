export const user = (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
        <path
            d="M10 11c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4m0-9C7.79 2 6 3.79 6 6s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4m0 10.9c2.97 0 6.1 1.46 6.1 2.1v1.1H3.9V15c0-.64 3.13-2.1 6.1-2.1m0-9a2.1 2.1 0 110 4.2 2.1 2.1 0 010-4.2"
            fillOpacity=".54"
            fillRule="evenodd"
        />
    </svg>
);

export const google = (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
        <g transform="">
            <g fillRule="evenodd">
                <path
                    d="m17.64 9.2a10.341 10.341 0 0 0 -.164-1.841h-8.476v3.481h4.844a4.14 4.14 0 0 1 -1.8 2.716v2.264h2.909a8.777 8.777 0 0 0 2.687-6.62z"
                    fill="#4285f4"
                />
                <path
                    d="m9 18a8.592 8.592 0 0 0 5.956-2.18l-2.909-2.258a5.43 5.43 0 0 1 -8.083-2.852h-3.007v2.332a9 9 0 0 0 8.043 4.958z"
                    fill="#34a853"
                />
                <path
                    d="m3.964 10.71a5.321 5.321 0 0 1 0-3.42v-2.332h-3.007a9.011 9.011 0 0 0 0 8.084z"
                    fill="#fbbc05"
                />
                <path
                    d="m9 3.58a4.862 4.862 0 0 1 3.44 1.346l2.581-2.581a8.649 8.649 0 0 0 -6.021-2.345 9 9 0 0 0 -8.043 4.958l3.007 2.332a5.364 5.364 0 0 1 5.036-3.71z"
                    fill="#ea4335"
                />
            </g>
            <path d="m0 0h18v18h-18z" fill="none" />
        </g>
    </svg>
);

export const facebook = (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
        <path
            d="m17.007 0h-16.014a.993.993 0 0 0 -.993.993v16.014a.993.993 0 0 0 .993.993h8.628v-6.961h-2.343v-2.725h2.343v-2a3.274 3.274 0 0 1 3.494-3.591 19.925 19.925 0 0 1 2.092.106v2.43h-1.428c-1.13 0-1.35.534-1.35 1.322v1.73h2.7l-.351 2.725h-2.364v6.964h4.593a.993.993 0 0 0 .993-.993v-16.014a.993.993 0 0 0 -.993-.993z"
            fill="#4267b2"
        />
        <path
            d="m28.586 24.041v-6.961h2.349l.351-2.725h-2.7v-1.734c0-.788.22-1.322 1.35-1.322h1.443v-2.434a19.924 19.924 0 0 0 -2.095-.106 3.27 3.27 0 0 0 -3.491 3.591v2h-2.343v2.73h2.343v6.961z"
            fill="#fff"
            transform="translate(-16.172 -6.041)"
        />
    </svg>
);
export const errorIcon = (
    <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="triangle-exclamation"
        className="svg-inline--fa fa-triangle-exclamation _errorIcon_1fn2z_30"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <path
            fill="currentColor"
            d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
        />
    </svg>
);

export const penNote = (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} viewBox="0 0 18 16" fill="none">
        <path
            d="M16.4062 1.78125L15.2188 0.59375C14.8438 0.21875 14.3125 0 13.8125 0C13.3125 0 12.7812 0.21875 12.4062 0.59375L2.40625 10.5938C2.125 10.875 1.9375 11.2188 1.84375 11.5938L1 15.4062C0.9375 15.7188 1.1875 16 1.46875 16C1.5 16 1.5625 16 1.59375 16C1.59375 16 4.21875 15.4375 5.40625 15.1562C5.78125 15.0938 6.09375 14.9062 6.375 14.625L16.4062 4.59375C17.1875 3.8125 17.1875 2.5625 16.4062 1.78125ZM5.65625 13.9375C5.53125 14.0625 5.375 14.1562 5.1875 14.1875C4.40625 14.375 3 14.6875 2.15625 14.875L2.84375 11.8125C2.875 11.625 2.96875 11.4375 3.09375 11.3125L10.8438 3.5625L13.4375 6.15625L5.65625 13.9375ZM15.6875 3.875L14.125 5.46875L11.5312 2.875L13.0938 1.3125C13.2812 1.125 13.5312 1 13.8125 1C14.0938 1 14.3438 1.125 14.5312 1.3125L15.6875 2.46875C16.0938 2.875 16.0938 3.5 15.6875 3.875Z"
            fill="#0093FC"
        />
    </svg>
);

export const trashNote = (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={16} viewBox="0 0 14 16" fill="none">
        <path
            d="M13.5 2H10.5L9.4375 0.625C9.15625 0.25 8.71875 0 8.25 0H5.75C5.25 0 4.8125 0.25 4.53125 0.625L3.5 2H0.5C0.21875 2 0 2.25 0 2.5C0 2.78125 0.21875 3 0.5 3H13.5C13.75 3 14 2.78125 14 2.5C14 2.25 13.75 2 13.5 2ZM4.75 2L5.34375 1.21875C5.4375 1.09375 5.59375 1 5.75 1H8.25C8.40625 1 8.53125 1.09375 8.625 1.21875L9.25 2H4.75ZM12.5 4C12.2188 4 12 4.25 12 4.5V13.5C12 14.3438 11.3125 15 10.5 15H3.5C2.65625 15 2 14.3438 2 13.5V4.5C2 4.25 1.75 4 1.5 4C1.21875 4 1 4.25 1 4.5V13.5C1 14.9062 2.09375 16 3.5 16H10.5C11.875 16 13 14.9062 13 13.5V4.5C13 4.25 12.75 4 12.5 4ZM4.5 13V6C4.5 5.75 4.25 5.5 4 5.5C3.71875 5.5 3.5 5.75 3.5 6V13C3.5 13.2812 3.71875 13.5 4 13.5C4.25 13.5 4.5 13.2812 4.5 13ZM7.5 13V6C7.5 5.75 7.25 5.5 7 5.5C6.71875 5.5 6.5 5.75 6.5 6V13C6.5 13.2812 6.71875 13.5 7 13.5C7.25 13.5 7.5 13.2812 7.5 13ZM10.5 13V6C10.5 5.75 10.25 5.5 10 5.5C9.71875 5.5 9.5 5.75 9.5 6V13C9.5 13.2812 9.71875 13.5 10 13.5C10.25 13.5 10.5 13.2812 10.5 13Z"
            fill="#0093FC"
        />
    </svg>
);
export const sideBar = (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={13} viewBox="0 0 16 13" fill="none">
        <path
            d="M0.125 1.0625C0.125 0.78125 0.371094 0.5 0.6875 0.5H15.3125C15.5938 0.5 15.875 0.78125 15.875 1.0625C15.875 1.37891 15.5938 1.625 15.3125 1.625H0.6875C0.371094 1.625 0.125 1.37891 0.125 1.0625ZM0.125 6.6875C0.125 6.40625 0.371094 6.125 0.6875 6.125H15.3125C15.5938 6.125 15.875 6.40625 15.875 6.6875C15.875 7.00391 15.5938 7.25 15.3125 7.25H0.6875C0.371094 7.25 0.125 7.00391 0.125 6.6875ZM15.3125 12.875H0.6875C0.371094 12.875 0.125 12.6289 0.125 12.3125C0.125 12.0312 0.371094 11.75 0.6875 11.75H15.3125C15.5938 11.75 15.875 12.0312 15.875 12.3125C15.875 12.6289 15.5938 12.875 15.3125 12.875Z"
            fill="url(#paint0_linear_652_1775)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_652_1775"
                x1={-4}
                y1={-5}
                x2={-4}
                y2={19}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#5EBBFF" />
                <stop offset={1} stopColor="#A174FF" />
            </linearGradient>
        </defs>
    </svg>
);
export const checked = (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={13} viewBox="0 0 12 13" fill="none">
        <path
            d="M6 0.5C2.67188 0.5 0 3.19531 0 6.5C0 9.82812 2.67188 12.5 6 12.5C9.30469 12.5 12 9.82812 12 6.5C12 3.19531 9.30469 0.5 6 0.5ZM8.69531 5.46875L5.69531 8.46875C5.57812 8.60938 5.41406 8.65625 5.25 8.65625C5.0625 8.65625 4.89844 8.60938 4.78125 8.46875L3.28125 6.96875C3.02344 6.71094 3.02344 6.3125 3.28125 6.05469C3.53906 5.79688 3.9375 5.79688 4.19531 6.05469L5.25 7.08594L7.78125 4.55469C8.03906 4.29688 8.4375 4.29688 8.69531 4.55469C8.95312 4.8125 8.95312 5.21094 8.69531 5.46875Z"
            fill="#48BD79"
        />
    </svg>
);

export const courseBack = (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14} viewBox="0 0 16 14" fill="none">
        <path
            d="M15.3125 0.5C14.9961 0.5 14.75 0.78125 14.75 1.0625V13.4375C14.75 13.7539 14.9961 14 15.3125 14C15.5938 14 15.875 13.7539 15.875 13.4375V1.0625C15.875 0.78125 15.5938 0.5 15.3125 0.5ZM7.82422 1.80078C7.61328 1.58984 7.22656 1.58984 7.01562 1.80078C6.80469 2.01172 6.80469 2.39844 7.01562 2.60938L11.1289 6.6875H0.6875C0.371094 6.6875 0.125 6.96875 0.125 7.25C0.125 7.56641 0.371094 7.8125 0.6875 7.8125H11.1289L7.01562 11.9258C6.80469 12.1367 6.80469 12.5234 7.01562 12.7344C7.22656 12.9453 7.61328 12.9453 7.82422 12.7344L12.8867 7.67188C12.9922 7.56641 13.0625 7.42578 13.0625 7.25C13.0625 7.10938 12.9922 6.96875 12.8867 6.86328L7.82422 1.80078Z"
            fill="url(#paint0_linear_676_2943)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_676_2943"
                x1={-4}
                y1={-5}
                x2={-4}
                y2={19}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#5EBBFF" />
                <stop offset={1} stopColor="#A174FF" />
            </linearGradient>
        </defs>
    </svg>
);

export const arrowBackWhite = (
    <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="arrow-right"
        className="svg-inline--fa fa-arrow-right "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
    >
        <path
            fill="currentColor"
            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
        />
    </svg>
);

export const navWhite = (
    <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="bars"
        className="svg-inline--fa fa-bars "
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
    >
        <path
            fill="currentColor"
            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
        />
    </svg>
);

export const edit = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 24 24"
        width="100%"
        height="100%"
    >
        <path d="M14.081,5.586.9,18.769A3.064,3.064,0,0,0,5.23,23.1L18.414,9.919Zm1.505,4.333L13,12.505,11.5,11l2.586-2.586ZM3.816,21.688a1.087,1.087,0,0,1-1.5,0,1.062,1.062,0,0,1,0-1.5l7.769-7.77,1.505,1.505Zm17.517-7.06L24,15.962,21.333,17.3,20,19.962,18.667,17.3,16,15.962l2.667-1.334L20,11.962ZM6.667,5.333,4,4,6.667,2.667,8,0,9.333,2.667,12,4,9.333,5.333,8,8Zm12.666-.666L17,3.5l2.333-1.167L20.5,0l1.167,2.333L24,3.5,21.667,4.667,20.5,7Z" />
    </svg>
);
export const view = (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
        <path d="m8,6.5c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm-1.5,3.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm0,5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm5-7h6c.829,0,1.5-.671,1.5-1.5s-.671-1.5-1.5-1.5h-6c-.829,0-1.5.671-1.5,1.5s.671,1.5,1.5,1.5Zm0,5h6c.829,0,1.5-.671,1.5-1.5s-.671-1.5-1.5-1.5h-6c-.829,0-1.5.671-1.5,1.5s.671,1.5,1.5,1.5Zm12.319,5.89c.241.379.241.841,0,1.22-.911,1.433-3.026,3.89-6.819,3.89s-5.91-2.459-6.82-3.89c-.24-.378-.24-.841,0-1.22.911-1.433,3.026-3.89,6.82-3.89s5.908,2.457,6.819,3.89Zm-4.819.61c0-1.105-.895-2-2-2s-2,.895-2,2,.895,2,2,2,2-.895,2-2Zm-.5-19.5H5.5C2.467,0,0,2.467,0,5.5v12c0,3.032,2.467,5.5,5.5,5.5h2c.829,0,1.5-.672,1.5-1.5s-.671-1.5-1.5-1.5h-2c-1.378,0-2.5-1.121-2.5-2.5V5.5c0-1.378,1.122-2.5,2.5-2.5h13c1.378,0,2.5,1.122,2.5,2.5v7.5c0,.828.671,1.5,1.5,1.5s1.5-.672,1.5-1.5v-7.5c0-3.033-2.467-5.5-5.5-5.5Z" />
    </svg>
);

export const home = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 50 50">
        <path d="M 24.962891 1.0546875 A 1.0001 1.0001 0 0 0 24.384766 1.2636719 L 1.3847656 19.210938 A 1.0005659 1.0005659 0 0 0 2.6152344 20.789062 L 4 19.708984 L 4 46 A 1.0001 1.0001 0 0 0 5 47 L 18.832031 47 A 1.0001 1.0001 0 0 0 19.158203 47 L 30.832031 47 A 1.0001 1.0001 0 0 0 31.158203 47 L 45 47 A 1.0001 1.0001 0 0 0 46 46 L 46 19.708984 L 47.384766 20.789062 A 1.0005657 1.0005657 0 1 0 48.615234 19.210938 L 41 13.269531 L 41 6 L 35 6 L 35 8.5859375 L 25.615234 1.2636719 A 1.0001 1.0001 0 0 0 24.962891 1.0546875 z M 25 3.3222656 L 44 18.148438 L 44 45 L 32 45 L 32 26 L 18 26 L 18 45 L 6 45 L 6 18.148438 L 25 3.3222656 z M 37 8 L 39 8 L 39 11.708984 L 37 10.146484 L 37 8 z M 20 28 L 30 28 L 30 45 L 20 45 L 20 28 z" />
    </svg>
);

export const homeActive = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 50 50">
        <path d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z" />
    </svg>
);
export const kink = (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 10 11" fill="none">
        <path
            d="M9.39379 9.23321C9.0651 9.45156 8.65127 9.35571 8.44241 9.04131C8.43292 9.02701 8.43292 9.02701 8.43292 9.02701L3.97587 10.9373C3.74252 11.0512 3.45241 10.9555 3.31001 10.7412L0.16768 6.01085C0.0252788 5.79648 0.0495717 5.49198 0.244949 5.321L3.73367 1.95298C3.73367 1.95298 3.73367 1.95298 3.72418 1.93869C3.51533 1.62429 3.58739 1.20567 3.91608 0.987317C4.23048 0.778461 4.6586 0.864817 4.86746 1.17922C5.06682 1.47933 4.98996 1.92174 4.67556 2.13059C4.54694 2.21604 4.39464 2.23482 4.24713 2.22982L4.07259 4.19953C4.05299 4.54211 4.3428 4.82333 4.68548 4.78107L6.87956 4.5182C6.87517 4.29455 6.97071 4.0663 7.18508 3.9239C7.49948 3.71504 7.9276 3.8014 8.14594 4.13009C8.3548 4.44449 8.25895 4.85832 7.94455 5.06718C7.73019 5.20958 7.48275 5.20917 7.26879 5.10413L6.17594 7.02475C6.00415 7.32425 6.16054 7.7147 6.48392 7.82944L8.35779 8.41781C8.41985 8.29419 8.5057 8.17537 8.63432 8.08993C8.94872 7.88107 9.36734 7.95314 9.58569 8.28183C9.79455 8.59623 9.70819 9.02435 9.39379 9.23321Z"
            fill="#F5C70E"
        />
    </svg>
);
export const alert = (
    <svg viewBox="0 0 24 24" style={{ width: 24, height: 24 }}>
        <path
            fill="currentColor"
            d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M15,15.6L13,14H4V10H13L15,8.4V15.6M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
        />
    </svg>
);
