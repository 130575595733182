const imgs = {
    unsetAvatar: require('./imgs/unset.png'),
    profileBanner: require('./imgs/profileBanner.png'),
    NoImg: require('./imgs/NoImg.png'),
    StudentRole: require('./imgs/StudentRole.png'),
    TeacherRole: require('./imgs/teacherRole.png'),
    AnimateStudent: require('./imgs/animateStudent.gif'),
    AnimateTeacher: require('./imgs/animateTeacher.gif'),
    loading: require('./imgs/loading.gif'),
    typeClassEnglish: require('./imgs/typeClassEnglish.png'),
    typeClassMath: require('./imgs/typeClassMath.png'),
    typeClassPhysic: require('./imgs/typeClassPhysic.png'),
    typeClassOther: require('./imgs/typeClassOther.png'),
    liveChat: require('./imgs/e7bf1f06-988d-4121-a602-9a03fa911143.gif'),
    newFeed: require('./imgs/new-feed.gif'),
    rank: require('./imgs/rank.gif'),
    meeting: require('./imgs/Meeting.gif'),
    create_meeting: require('./imgs/create-meeting.png'),
    join_meeting: require('./imgs/join.png'),
    validate_zoom: require('./imgs/validateZoom.gif'),
    decor_rank: require('./imgs/decor-rank.png'),
    decor_rank_2: require('./imgs/decor-rank-2.png'),
    thank: require('./imgs/thank.gif'),
    thankBanner: require('./imgs/thankBanner.jpg'),
    togetherBanner: require('./imgs/together.jpg'),
    studentBanner: require('./imgs/studentBanner.jpg'),
    teacherBanner: require('./imgs/teacherBanner.jpg'),
    blog1: require('./imgs/blog1.jpg'),
    blog2: require('./imgs/blog2.jpg'),
};

export default imgs;
